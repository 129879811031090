<template>
  <div class="ud-body ud-body-card">
    <a-row :gutter="16">
      <a-col :lg="6" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" :body-style="{ padding: '24px 16px' }">
          <div class="ud-table-tool" v-role="'assest-admin'">
            <a-space size="middle">
              <a-button type="primary" @click="openEdit()">新建 </a-button>
              <a-button type="primary" @click="openEdit(current)"
                >修改
              </a-button>
              <a-button danger type="primary" @click="remove">删除 </a-button>
            </a-space>
          </div>
          <a-tree
            :tree-data="data"
            v-model:expanded-keys="expandedRowKeys"
            v-model:selected-keys="selectedRowKeys"
            :replaceFields="{
              children: 'children',
              title: 'categoryName',
              value: 'categoryId',
              key: 'categoryId'
            }"
            @select="onTreeSelect"
          />
        </a-card>
      </a-col>
      <a-col :lg="18" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false">
          <index-list
            v-if="current"
            :category-code="current.categoryCodeKey"
            :category-list="data"
          />
        </a-card>
      </a-col>
    </a-row>
  </div>
  <!-- 编辑弹窗 -->
  <cate-edit
    v-model:visible="showEdit"
    :data="editData"
    :category-list="data"
    @done="query"
  />
</template>

<script>
import CateEdit from './category-edit'
import IndexList from './index-list'
import { createVNode } from 'vue'
// import * as categorydApi from '@/api/ams/assetsCategory'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'categoryIndex',
  components: {
    CateEdit,
    IndexList
  },

  data() {
    return {
      // 加载状态
      loading: true,
      // 树形数据
      data: [],
      // 树展开的key
      expandedRowKeys: [],
      // 树选中的key
      selectedRowKeys: [],
      // 选中数据
      current: null,
      // 是否显示表单弹窗
      showEdit: false,
      // 编辑回显数据
      editData: null
    }
  },

  mounted() {
    this.query()
    // this.getCategoryData()
  },

  methods: {
    // getCategoryData() {
    //   categorydApi.tree().then((res) => {
    //     if (res.code === 0) {
    //       this.data = res.data
    //     }
    //   })
    // },
    /* 查询 */
    query() {
      const hide = this.$message.loading('请求中..', 0)
      this.loading = true
      this.$http
        .get('/ams/assetsCategory/tree')
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            const eks = []
            this.expandedRowKeys = eks
            this.data = res.data.data
            console.log(this.data)
            if (this.data.length) {
              this.selectedRowKeys = [this.data[0].categoryId]
              this.onTreeSelect()
            } else {
              this.selectedRowKeys = []
              this.current = null
            }
          } else {
            this.$message.error(res.data.msg)
          }
          hide()
        })
        .catch((e) => {
          this.loading = false
          hide()
          this.$message.error(e.message)
        })
    },
    /* 选择数据 */
    onTreeSelect() {
      this.$util.eachTreeData(this.data, (d) => {
        if (this.selectedRowKeys.indexOf(d.categoryId) !== -1) {
          // debugger
          this.current = d
          this.current.categoryCodeKey = this.current.categoryCode
          return false
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(item) {
      this.editData = Object.assign(
        {},
        item
      )
      this.showEdit = true
    },
    /* 删除 */
    remove() {
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的分类吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          this.$http
            .delete('/ams/assetsCategory/' + this.current.categoryId)
            .then((res) => {
              hide()
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                this.query()
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .ant-card {
    min-height: calc(100vh - 122px);
  }
}
</style>
