<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改分类' : '添加分类'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="父代码:" name="categoryPcode">
            <a-tree-select
              allow-clear
              placeholder="请选择父代码"
              v-model:value="form.categoryPcode"
              :tree-data="categoryList"
              :replaceFields="{
                children: 'children',
                title: 'categoryName',
                value: 'categoryCode',
                key: 'categoryCode'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
            />
          </a-form-item>
          <a-form-item label="名称:" name="categoryName">
            <a-input
              allow-clear
              :maxlength="20"
              placeholder="请输入名称"
              v-model:value="form.categoryName"
            />
          </a-form-item>
          <a-form-item label="代码:" name="categoryCode">
            <a-input
              allow-clear
              :maxlength="20"
              placeholder="请输入代码"
              v-model:value="form.categoryCode"
            />
          </a-form-item>
          <a-form-item label="单位:" name="measurementUnit">
            <a-input
              allow-clear
              :maxlength="20"
              placeholder="请输入单位"
              v-model:value="form.measurementUnit"
            />
          </a-form-item>
          <a-form-item label="说明:" name="comment">
            <a-input
              allow-clear
              :maxlength="20"
              placeholder="请输入说明"
              v-model:value="form.comment"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as categoryApi from '@/api/ams/assetsCategory'
export default {
  name: 'categoryEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部分类
    categoryList: Array,
    // 分类id
    categoryId: Number
  },

  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data, {
          categoryPcode:
            this.data.categoryPcode === '0' ? null : this.data.categoryPcode
        })
        this.isUpdate = !!this.data.categoryId
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },

  mounted() {},

  methods: {
    cate(data) {
      console.log('zzzzzz', data)
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (!this.form.categoryPcode) {
            this.form.categoryPcode = '0'
          }
          categoryApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
    /* 查询机构类型 */
    // queryOrganizationType() {
    //   this.$http.get('/sys/dictdata', {
    //     params: {
    //       dictCode: 'organization_type'
    //     }
    //   }).then(res => {
    //     if (res.data.code === 0) {
    //       this.organizationTypeList = res.data.data
    //     } else {
    //       this.$message.error(res.data.msg)
    //     }
    //   }).catch(e => {
    //     this.$message.error(e.message)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>
