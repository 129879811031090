<template>
  <!-- 表格 -->
  <ud-pro-table
    ref="table"
    row-key="categoryId"
    :datasource="url"
    :columns="columns"
    :where="where"
    tool-class="ud-toolbar-form"
    :scroll="{ x: 'max-content' }"
  >
    <template #toolbar>
      <a-row :gutter="16">
        <a-col :lg="6" :md="8" :sm="24" :xs="24">
          <a-input
            v-model:value.trim="where.categoryCode"
            placeholder="请输入编号"
            allow-clear
          />
        </a-col>
        <a-col :lg="6" :md="8" :sm="24" :xs="24">
          <a-input
            v-model:value.trim="where.categoryName"
            placeholder="请输入名称"
            allow-clear
          />
        </a-col>
        <a-col :lg="6" :md="8" :sm="24" :xs="24">
          <a-checkbox v-model:checked="ischildren">是否包含子级</a-checkbox>
        </a-col>
        <a-col :lg="6" :md="8" :sm="24" :xs="24">
          <a-space size="middle" >
            <a-button type="primary" @click="init">查询 </a-button>
            <a-button type="primary" v-role="'assest-admin'" @click="openEdit()">新建 </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
    <template #roles="{ record }">
      <a-tag v-for="(item, index) in record.roles" :key="index" color="blue"
        >{{ item.roleName }}
      </a-tag>
    </template>
    <template #enable="{ text, record }">
      <a-switch
        :checked="text === 0"
        @change="(checked) => changeState(checked, record)"
      />
    </template>
    <template #action="{ record }">
      <a-space v-role="'assest-admin'">
        <a @click="openEdit(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm title="确定要删除此用户吗？" @confirm="remove(record)">
          <a class="ud-text-danger">删除</a>
        </a-popconfirm>
      </a-space>
    </template>
  </ud-pro-table>
  <!-- 编辑弹窗 -->
  <cate-edit
    v-model:visible="showEdit"
    :data="editData"
    :category-list="categoryList"
    :category-id="categoryId"
    @done="query"
  />
</template>

<script>
import * as categoryApi from '@/api/ams/assetsCategory'
import CateEdit from './category-edit'
export default {
  name: 'categoryList',
  components: { CateEdit },
  props: {
    // 分类编号
    categoryCode: String,
    // 全部分类
    categoryList: Array
  },
  data() {
    return {
      // 表格数据接口
      url: '/ams/assetsCategory/page',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          align: 'center',
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '父级编码',
          dataIndex: 'categoryPcode',
          sorter: true
        },
        {
          title: '编号',
          dataIndex: 'categoryCode',
          sorter: true
        },
        {
          title: '名称',
          dataIndex: 'categoryName',
          sorter: true
        },
        {
          title: '单位',
          dataIndex: 'measurementUnit',
          sorter: true
        },
        {
          title: '说明',
          dataIndex: 'comment',
          sorter: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
          width: 150,
          customRender: ({ text }) => this.$util.toDateString(text)
        },
        {
          title: '操作',
          key: 'action',
          width: 120,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {
        categoryPcode: null,
        categoryPcodeChildren: null
      },
      // 当前编辑数据
      editData: null,
      // 是否显示编辑弹窗
      showEdit: false,
      ischildren: true
    }
  },
  watch: {
    /* 监听机构id变化 */
    categoryCode() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (this.ischildren) {
        this.where.categoryPcode = null
        this.where.categoryPcodeChildren = this.categoryCode.replace(RegExp('0*$'), '')
      } else {
        this.where.categoryPcodeChildren = null
        this.where.categoryPcode = this.categoryCode
      }
      this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1, where: this.where })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.editData = row
      this.showEdit = true
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      categoryApi
        .deleteById(row.categoryId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
</style>
